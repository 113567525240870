const Container = {
  variants: {
    "globalHeader": {
      backgroundColor: 'brandWhite.50',
      maxWidth: 'unset',
      fontSize: "18px",
      lineHeight: '28px',
      marginTop: '3',
      minHeight: '39px',
      paddingTop: '2',
      paddingBottom : '1px',
      width: '100%'
    },
    "productHeading": {
      fontSize: ['15px','16px','16px','16px'],
      padding: '0px'
    },
    "pageHeading": {
      fontSize: "18px",
      lineHeight: '30px',
      // marginBottom: '6',
      p: {
        marginTop: '15px',
        fontSize: ["16px","16px","18px","18px"]
      }
    },  
    "productInfo": {
      fontSize: '16px',
      textAlign: 'left',
      marginLeft: '0px'
    },
    "button": {
      margin: '10px',
      marginTop: '20px',
      marginBottom: '20px'
    },
    "Black": {
      backgroundColor: "#000000",
      color: "#ffffff"
    },
    "Grey": {
      backgroundColor: "#f6f6f6",
      color: "#4a4a4a"
    },
    "GreyOnWhite": {
      backgroundColor: "white",
      color: "#4a4a4a"
    }
  }
}

export default Container
