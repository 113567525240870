import Button from './components/button'
import Text from './components/text'
import Heading from './components/heading'
import Container from './components/container'
import Link from './components/link'
import Accordion from './components/accordian'
import Input from './components/input'
import Textarea from './components/textArea'
import FormLabel from './components/formLabel'
import Table from './components/table'
import IngmarsonCard from './components/card'
import { createBreakpoints } from "@chakra-ui/theme-tools"

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
})

export default {
  breakpoints: breakpoints,
  textStyles: {
    h1: {
      fontSize: "32px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "24px",
      fontWeight: "semibold",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    h3u: {
      fontSize: "18px",
      fontWeight: "semibold",
      textDecoration: 'underline',
      textDecorationThickness: '4px',
      textUnderlineOffset: '4px',
      color: 'brandWhite.500',
      fontWeight: '600',
      marginBottom: '3'
    },
    h4: {
      fontSize: ["16px", "18px"],
      fontWeight: "semibold",
    },
    h4u: {
      fontSize: "14px",
      fontWeight: "semibold",
      textDecoration: 'underline',
      textDecorationThickness: '4px',
      textUnderlineOffset: '4px',
      color: 'brandWhite.500',
      fontWeight: '600',
      marginBottom: '3'
    },
    h5: {
      fontSize: "13px",
      fontWeight: "600",
      color: 'brandWhite.600',
    },
    t5: {
      fontSize: "13px",
      color: 'brandWhite.500',
    },
    h6: {
      fontSize: "12px",
      fontWeight: "semibold",
      color: 'brandWhite.500',
    },
    h7: {
      fontSize: "10px",
      fontWeight: "semibold",
      color: 'brandWhite.600',
    },
    t6: {
      fontSize: "12px",
      color: 'brandWhite.500',
    },
    t7: {
      fontSize: "10px",
      color: 'brandWhite.500',
    }

  },
  styles: {
    global: {
      _focus: {
        outline: 0
      },      
      a: {
        _focus: {
          outline: 'none'
        }
      },
      body: {
        button: {
          outline: 'none'
        },
        color: "brandWhite.700",
      },
    },
  },
  colors: {
    brandWhite:
    {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d'

    },
    brandYellow: {
      50: '#ffffda',
      100: '#ffffad',
      200: '#fffe7d',
      300: '#fffe4b',
      400: '#fffe1a',
      500: '#e6e400',
      600: '#b3b200',
      700: '#807f00',
      800: '#4d4c00',
      900: '#1b1900',
    },
  },
  fonts: {
    body: "ApercuLight",
    heading: "Aktifo-A",
  },
  components: {
    IngmarsonCard,
    Table,
    Accordion,
    Button,
    Input,
    FormLabel,
    Textarea,
    Link,
    Container,
    Text,
    Heading
  },
}
