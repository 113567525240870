import React, { useEffect } from 'reactn'
import { graphql, Link, StaticQuery } from 'gatsby';
import IngmarsonSearch from "../components/ingmarsonSearch"
// import Snipcart from '../components/snipcart'
// import { isReloadRequired, reload } from "./reload"
import Auth from '@aws-amplify/auth'
import { navigate } from "@reach/router"
import { isLoggedIn, signInRequired, setUserID } from "../components/auth/auth"

// const Snipcart = React.lazy(() => import ('../components/snipcart' )) ;
// import ('../components/snipcart' )) ;

import Cart from '../components/snipcart'
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
// const snipcartURL = process.env.GATSBY_ACTIVE_ENV == 'production' ? "/snipcart.js" : "/snipcartd.js"

const cookieName = 'ingCookieConsent'
const CommonState = ({ snipcartData, pageContext, location, children }) => {

  const {
    rebuildDate,
    apiEndpoint
    // snipcartKey
  } = snipcartData;
  console.log("CHEECK AUTHXXX", location)
  const isSSR = typeof window === "undefined"

  if (!isSSR) {
    window.dataLayer = window.dataLayer || [];
  }
  function gtag() {
    console.log("GALL GTAG", arguments)
    window.dataLayer.push(arguments);
  }
  useEffect(() => {
    async function checkAuth() {
      console.log("CHEECK AUTH", location)
      if (!isLoggedIn() && (!signInRequired || (signInRequired && !location.pathname.startsWith('/admin/login')))) {
        const anonymousUser = await Auth.currentCredentials()
        setUserID(anonymousUser.identityId)
        console.log("GOT ANON USER", anonymousUser)
      } else {
        console.log("AUTHLOG NOT GETTING ANON AS SIGN IN IS REQUIRED")
        if (isLoggedIn()) {
          // alert("ALREADY SIGNED IN")
        } else {
          // alert("NO ANON REQUESTED")
        }
      }
    }

    checkAuth()

    // if (!isSSR) {
    //   const cookiesAccepted = getCookieConsentValue('ingCookieConsent')
    //   const consent = cookiesAccepted === 'true' ? 'granted' : 'denied'
    //   console.log("COOKIE CONSENT", window.dataLayer)
    //   window.dataLayer.push('consent', 'default',{
    //     ad_storage:  consent,
    //     analytics_storage: consent,
    //     functionality_storage: consent,
    //     personalization_storage: consent,
    //     security_storage: consent,
    //     wait_for_update: 500
    //  })
    // }    

    const cookieEnabled = getCookieConsentValue(cookieName)
    const trackContent = cookieEnabled === 'true' ? "granted" : "denied"
    gtag('set', 'url_passthrough', true);
    gtag("consent", "default", {
      ad_storage: trackContent,
      ad_user_data: trackContent,
      ad_personalization: trackContent,
      analytics_storage: trackContent,
      functionality_storage: "granted",
      personalization_storage: trackContent,
      security_storage: "granted",
      wait_for_update: 500
    });

    // if (typeof window !== 'undefined') {
    //   if (isReloadRequired(rebuildDate)) {
    //     console.log("RELOADING")
    //     reload()
    //     window.location.reload()
    //   } else {
    //     console.log("RELOAD NOT NEEDED")
    //   }
    // }
  }, [])

  if (typeof window !== 'undefined' && signInRequired() && !isLoggedIn() && location.pathname !== `/admin/login` && location.pathname !== `/admin/signup`) {
    navigate("/admin/login")
    return null
  }



  return (

    <>
      <IngmarsonSearch pageContext={pageContext} location={location}>
        {children}
      </IngmarsonSearch>

      <CookieConsent
        location="bottom"
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        onAccept={(acceptedByScrolling) => {
          gtag("consent", "update", {
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
            wait_for_update: 500
          });
        }}
        buttonText="OK"
        cookieName={cookieName}
        style={{ background: "#000000" }}
        buttonStyle={{ background: "white", color: "#000000", fontWeight: 600, fontSize: "14px" }}
        expires={720}
      >We use cookies for a better shopping experience.{" "}
        <span style={{ fontSize: "10px" }}>By accepting, you agree to our <Link to='/privacy'>privacy policy</Link>. </span>
      </CookieConsent>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Cart />
        </React.Suspense>
      )}
    </>

  )
}

export default props => (
  <StaticQuery
    query={graphql`
        query SnipcartInfoQuery {
            site {
              siteMetadata {
                rebuildDate
                apiEndpoint
              }
            }
          }
        `}
    render={({ site }) => <CommonState snipcartData={site.siteMetadata} {...props} />}
  />
)

