import { theme } from "@chakra-ui/react";

const Button = {
  baseStyle: (props) => ({
    ...theme.components.Button.variants.outline,
    fontFamily: "Aktifo-A",
    whiteSpace: 'break-spaces',
    border: "4px solid",
    width: 'fix-content',
    borderColor: `${props.colorScheme}.900`,
    color: `${props.colorScheme}.900`,
    lineHeight: "1.22",
    fontWeight: "500",
    wordBreak: 'break-none',
    borderRadius: "0px",
    display: 'inline-block',
    _hover: {
      bg: "hsla(0,0%,95.3%,.23137254901960785)"
    },
    textTransform: "uppercase",
    _focus: {
      boxShadow: 'none'
    },
    _active: {
      boxShadow: 'none'
    }
  }),
  sizes: {
    sm_s: {
      fontSize: "16px",
      padding: '12px 24px 12px 24px',
      _hover: {
        bg: "hsla(0,0%,80%,.53137254901960785)"
      },
    },
    xs: {
      fontSize: ["16px", "16px", "16px", "16px"],
      padding: '18px 28px 18px 28px',
    },
    cart: {
      fontSize: ["16px", "16px", "16px", "16px"],
      padding: '12px 14px 12px 14px',
    },
    sm: {
      fontSize: ["20px", "20px", "20px", "20px"],
      padding: '20px 30px 20px 30px',
    },
    md: {
      fontSize: "26px",
      padding: '10px 20px 10px 20px',
    },
    lg: {
      fontSize: "26px",
      padding: '10px 20px 10px 20px',
    },
    xl: {
      fontSize: "26px",
      padding: '10px 50px 10px 50px',
    },
  },
  variants: {
    center: {
      height: 'unset',
      position: 'absolute',
      transform: "translate(-50%, -50%)",
      top: '50%',
      left: '50%',
    },
    top: {
      height: 'unset',
      position: 'absolute',
      top: '30%',
      left: '50%'
    },
    shipping: {
      fontFamily: 'ApercuLight',
      border: "3px solid",
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      color: "black",
      height: '60px',
      width: '100%',
      fontSize: '13px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "brandWhite.50",
        // color: 'white'
      }
    },
    receipt: {
      fontFamily: 'ApercuLight',
      border: "3px solid",
      marginTop: '0',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      color: "black",
      height: '60px',
      // width: '100%',
      fontSize: '14px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "brandWhite.500",
        color: 'white'
      }
    },
    packaging: {
      fontFamily: 'ApercuLight',
      border: "3px solid",
      marginTop: '0',
      marginBottom: '0',
      padding: '2',
      marginLeft: '0',
      marginRight: '0',
      color: "black",
      height: ['80px','80px','70px','70px'],
      width: '100%',
      fontSize: '14px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "brandWhite.50",
        // color: 'black'
      }
    },
    packagingCircular: {
      fontFamily: 'ApercuLight',
      border: "3px solid",
      marginTop: '0',
      padding: '2',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      color: "black",
      height: ['80px','80px','70px','70px'],
      width: '100%',
      fontSize: '14px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "brandWhite.50",
        // color: 'black'
      }
    },
    checkout: {
      fontFamily: 'ApercuLight',
      border: "3px solid",
      marginTop: '2',
      color: "black",
      height: '50px',
      width: '100%',
      fontSize: '14px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "black !important",
        color: 'white'
      },
      _disabled: {
        bg: "black",
        color: 'white'
      }
    },
    voucher: {
      fontFamily: 'ApercuLight',
      border: "3px solid",
      marginLeft: '5px',
      // marginTop: '5',
      color: "black",
      height: '50px',
      width: '100%',
      fontSize: '14px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "black",
        color: 'white'
      }
    },
    aidesign: {
      fontFamily: 'ApercuLight',
      border: "1px solid",
      marginLeft: '20px',
      marginBottom: '2',
      paddingTop: '2px',
      color: "black",
      height: '20px',
      width: 'unset',
      fontSize: '13px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "black",
        color: 'white'
      }
    },

    aidesignSelected: {
      fontFamily: 'ApercuLight',
      border: "1px solid",
      marginLeft: '20px',
      marginBottom: '2',
      paddingTop: '2px',
      color: "white",
      backgroundColor: "black",
      height: '20px',
      width: 'unset',
      fontSize: '13px',
      fontWeight: "400",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "black",
        color: 'white'
      }
    },
    
    onWhiteFilter: {
      border: "1px solid",
      color: "black",
      height: '50px',
      width: '100%',
      fontWeight: "500",
      borderColor: "black",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "grey.500"
      }
    },
    bottom_large: {
      height: 'unset',
      position: 'absolute',
      transform: "translate(-50%, -50%)",
      top: '70%',
      left: '50%',
      minWidth: '180px'
    },
    bottom_medium: {
      height: 'unset',
      position: 'absolute',
      transform: "translate(-50%, -50%)",
      top: ['60%', '60%', '70%', '70%'],
      left: '50%',
      paddingTop: '8px',
      paddingBottom: '8px',
      minWidth: ['140px', '140px', '180px', '180px']
    },
    bottom_small: {
      border: "2px solid",
      height: 'unset',
      position: 'absolute',
      fontFamily: "ApercuLight",
      transform: "translate(-50%, -50%)",
      top: '70%',
      left: '50%',
      minWidth: '100px'
    },
    onBlack: {
      color: "white",
      borderColor: "white",
    },
    cart: {
      whiteSpace: 'nowrap',
      transform: 'unset',
      color: "black",
      height: "100%",
      borderColor: "black",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "ApercuLight",
      border: "3px solid",
      minHeight: '50px',
      maxHeight: '50px',
      minWidth: '100px'
    },
    footer: {
      whiteSpace: 'nowrap',
      transform: 'unset',
      color: "gray.200",
      height: "100%",
      borderColor: "whiteAlpha.200",
      fontFamily: "ApercuLight",
      border: "3px solid",
      _hover: {
        bg: "whiteAlpha.200"
      }
    },
    refinement: {
      border: "2px solid",
      color: 'black',
      fontFamily: "ApercuLight",
      minWidth: '70px',
      // height: '15px',
      fontWeight: "400",
      borderColor: "grey.600",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "brandWhite.50"
      }
    },
    contact: {
      border: "2px solid",
      color: 'black',
      fontSize: "16px",
      fontFamily: "ApercuLight",
      fontWeight: "400",
      borderColor: "grey.600",
      borderRadius: "0px",
      isFullWidth: false,
      _hover: {
        bg: "brandWhite.50"
      }
    },
    onWhite: {
      color: "black",
      borderColor: "black",
    },
  },
  defaultProps: {
    colorScheme: "whiteAlpha",
  },
}

export default Button