const IngmarsonCard = {
  baseStyle: {
  },
  variants: {
    sizes: {
      color: 'brandWhite.700',
    },
    radioButton: {
      textAlign: 'center',
      cursor: "pointer",
      borderWidth: "3px",
      color: 'black',
      borderColor: 'brandWhite.100',
      _checked: {
        color: "black",
        borderColor: "black",
      },
      minW: 8,
      py: "9px",
      pl: 1,
      pr: 1,
      minHeight: '50px'
    },
    radioButtonNoStock: {
      background: "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='grey' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='grey' stroke-width='1'/></svg>\")",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      backgroundSize: '100% 100%,auto',
      textAlign: 'center',
      cursor: "pointer",
      borderWidth: "3px",
      color: 'brandWhite.400',
      borderColor: 'brandWhite.100',
      _checked: {
        color: 'brandWhite.400',
        borderColor: "black",
      },
      minW: 8,
      py: "9px",
      pl: 1,
      pr: 1,
      minHeight: '50px'
    }
  }
}

export default IngmarsonCard