import { useGlobal } from 'reactn'
import { useLocation } from "@reach/router"

const PaymentVisibility = () => {
  const [showPayment] = useGlobal('showPayment');
  var complete = false

  const location = useLocation();

  if (!complete && typeof document !== 'undefined') {
    hide()
  }
  function hide() {
    var paymentForm = document ? document.getElementById('snipcart-checkout-step-payment') : false

    if (paymentForm) {
      if (showPayment != null && !showPayment) {
        paymentForm.style.visibility = "hidden"
        paymentForm.style.display = "none"

      } else {
        paymentForm.style.visibility = "visible"
        paymentForm.style.display = "block"
      }
      complete = true
    } else {
      if (location.href.toLowerCase().includes("checkout")) {
        setTimeout(() => {
          hide()
        }, 500)
      }

    }

  }


  return null
}

export default PaymentVisibility
