const isSignInRequired = process.env.GATSBY_MANDATORY_SIGNIN
const isBrowser = typeof window !== `undefined`

export const setUser = user => (window.localStorage.gatsbyUser = JSON.stringify(user))

export const setUserID = userID => (window.localStorage.gatsbyUserID = userID.replace("eu-west-1:", ""))

const getAWSUserID = () => {
  if (window.localStorage.gatsbyUserID) {
    let userID = window.localStorage.gatsbyUserID
    return userID ? userID : "Unknown"
  }
  return "Unknown"
}

const getUser = () => {
  if (window.localStorage.gatsbyUser) {
    let user = JSON.parse(window.localStorage.gatsbyUser)
    return user ? user : {}
  }
  return {}
}

export const signInRequired = () => {
  return isSignInRequired.toLocaleLowerCase() === 'true'
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  if (user) return !!user.username
}

export const getUserID = () => isBrowser && getAWSUserID()

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  setUser({})
  window.localStorage.setItem('gatsbyUser', null)
  callback()
}