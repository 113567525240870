import React from "reactn"
import { SiteContext } from "./src/state/rootstate"
import { Amplify } from "aws-amplify";
import { Auth } from 'aws-amplify';
import { setUser } from './src/components/auth/auth'
import CommonState from "./src/state/commonState";

// Amplify.Logger.LOG_LEVEL = 'DEBUG'; 
Amplify.configure({
  Auth: {
    mandatorySignIn: process.env.GATSBY_MANDATORY_SIGNIN && process.env.GATSBY_MANDATORY_SIGNIN.toLocaleLowerCase() === 'true' ? true : false,
    region: 'eu-west-1',
    identityPoolRegion: 'eu-west-1',
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "api",
        region: 'eu-west-1',
        endpoint: process.env.GATSBY_API_ENDPOINT
      }
    ]
  }
})

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        ...user.attributes,
        username: user.username
      }
      setUser(userInfo)
    })
    .catch(err => {
      window.localStorage.setItem('gatsbyUser', null)
    })
}

export const wrapRootElement = ({ element }) => (
  <SiteContext>{element}</SiteContext>
)

export function wrapPageElement({ element, props }) {
  const { location } = props
  const Layout = element.type.Layout ?? React.Fragment
  return (
    <CommonState pageContext={props.pageContext} location={location}>
      <Layout location={location} indexName={props.pageContext.index} {...props}>{element}</Layout>
    </CommonState>
  )
}

export const onClientEntry = () => {
  const LogRocket = require('logrocket')
  LogRocket.init('ingmarson/ingmarson-website', {
    dom: {
      textSanitizer: false,
      inputSanitizer: true,
    }
  });
  const setupLogRocketReact = require('logrocket-react')
  setupLogRocketReact(LogRocket)

}

// export function onServiceWorkerUpdateReady() { window.location.reload() };



