import React, { setGlobal, useState, createContext, useContext, useEffect } from 'reactn'

import { graphql } from "gatsby"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { Global } from '@emotion/react'
import SecureLS from "secure-ls";
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import myTheme from "../styles/theme"
import fonts from "../styles/fonts"
import { LeftDrawer } from '../components/drawer'
import { Header } from '../components/header'
import { Helmet } from 'react-helmet';
import md5 from 'crypto-js/md5';
import { set } from 'react-ga'

const defaultState = {
  design: {},
  scarfOrder: {},
  links: [],
  scarves: {},
  scarfID: '',
}
export const GlobalContext = createContext(defaultState);


const theme = extendTheme(myTheme)
const klarnaEndpoint = process.env.GATSBY_KLARNA_ENDPOINT
const klarnaKey = process.env.GATSBY_KLARNA_KEY

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

let cartStateHash = ""
var ls;
var cartData;

function createContentDigest(content) {
  return md5(content).toString()
}

if (typeof window !== 'undefined' && localStorage) {
  ls = new SecureLS({});
  cartData = ls.get('ingmarson_cart');
  cartStateHash = createContentDigest(cartData)
}

export const SiteContext = ({ children }) => {

  const [store, setStore] = useState(defaultState);

  const updateGlobal = async (objectToUpdate) => {
    console.log("UPDATEING GLOBAL E", store, objectToUpdate)
    
    let newScarfOrder = { ...store.scarfOrder, ...objectToUpdate.scarfOrder }
    const newStore = { ...store, ...objectToUpdate }
    newStore.scarfOrder = newScarfOrder
    setStore(newStore)
    saveState(newStore)
    console.log("UPDATEING GLOBAL F", newStore)    
  };

  var persistData = null
  var processingState = false

  // useEffect(() => {
  //   saveState(store)
  // }, [store])

  // const [store, setStore] = useState({
  //   design: {
  //     hasDesign: false      
  //   },
  //   setDesign: () => {}    
  // })

  React.useEffect(() => {
    setGlobal({ sidebarOpen: false })
    setGlobal({ client: client })
    setGlobal({ showPayment: true })

    if (cartData) {
      var cartJSON = JSON.parse(cartData)
      // console.log("CART INITIALISED", cartInitialised)
      console.log("UPDATEING GLOBAL - LOADING STOREW FROM SAVED", cartJSON)
      setStore({
        design: cartJSON.design ? cartJSON.design : {},
        links: cartJSON.links ? cartJSON.links : [],
        scarfOrder: cartJSON.scarfOrder ? cartJSON.scarfOrder : {},
        scarves: cartJSON.scarves ? cartJSON.scarves : {},
        scarfID: cartJSON.scarfID ? cartJSON.scarfID : '',
      }
      )


      // setStore({
      //   design: cartJSON.design ? cartJSON.design : {},
      //   links: cartJSON.links ? cartJSON.links : [],
      //   scarves: cartJSON.scarves ? cartJSON.scarves : {},
      //   scarfID: cartJSON.scarfID ? cartJSON.scarfID : 'xxxxx',
      // })
    }

    // addCallback((global) => {
    //   console.log("CALL SAVED STATE ADD CALLBACK")
    //   if (!processingState) {
    //     processingState = true
    //     if (typeof window !== 'undefined' && localStorage) {
    //       saveState({
    //           design: global.design,
    //           links: global.links,
    //           scarves: global.scarves,
    //           scarfID: global.scarfID
    //       })
    //       while (persistData != null) {
    //         const state = persistData
    //         persistData = null
    //         saveState(state)        
    //       }    
    //     }
    //     processingState = false
    //   } else {
    //     // Store for onward Processing
    //     persistData = {
    //           design: global.design,
    //           links: global.links,
    //           scarves: global.scarves,
    //           scarfID: global.scarfID
    //       }
    //   }
    //   console.log("CALL SAVED STATE ADD CALLBACK END")
    //   return null
    // })

  }, [])


  async function saveState(processingState) {
    console.log("CALL SAVED STATE", processingState)
    const stateString = JSON.stringify(processingState)
    const cartHash = createContentDigest(stateString)
    console.log("SETTING CART DATA CART HASH", cartHash)
    if (cartHash != cartStateHash) {
      cartStateHash = cartHash
      ls.set('ingmarson_cart', stateString)
      console.log("SETTING CART DATA")
    } else {
      console.log("*********** NOT SETTING CART DATA")
    }
    console.log("CALL SAVED STATE END")
  }


  // const snipcartURL = process.env.GATSBY_ACTIVE_ENV == 'production' ? "/snipcart.js" : "/snipcartd.js"


  return (

    <GlobalContext.Provider value={{ store, updateGlobal }}>
      <Helmet lang="en">
        <script async defer type="text/javascript" src="//l.getsitecontrol.com/pwp62y14.js"></script>
        <link rel="preconnect" href="https://app.snipcart.com" />
        <link rel="preconnect" href="https://cdn.snipcart.com" />
        <link rel="preconnect" href={klarnaEndpoint} />
        <script type="text/javascript" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        <script type="text/javascript" src="https://x.klarnacdn.net/kp/lib/v1/api.js" async></script>
        <script type="text/javascript" src="https://x.klarnacdn.net/kp/lib/v1/api.js" async></script>
        <script type="text/javascript" async src={klarnaEndpoint} data-client-id={klarnaKey}></script>

        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <script src={snipcartURL} type="text/javascript" /> */}

        {/* <script async src={gtagURL} />
        <script dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '` + gtag + `');
        `,
        }} />
        <script dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K3TP3RX');`,
        }} /> */}

      </Helmet>

      <ChakraProvider theme={theme}>
        <Global styles={fonts} />
        <Header />
        <LeftDrawer />
        {children}
      </ChakraProvider>

    </GlobalContext.Provider>
  )
}

export const useStore = () => {
  const store = useContext(GlobalContext);
  console.log("XXXXX", store)
  return store;
}

export const query = graphql`
  fragment SiteData on DatoCmsSite {
      id
      globalSeo {
        siteName
        titleSuffix
        twitterAccount
        facebookPageUrl
        fallbackSeo {
          title
          description
          twitterCard
          image {
            url
            gatsbyImageData
            smartTags
          }
        }
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  `

